var folders = {
    created(){
        console.log('Hola desde los mixins')
    },
    methods: {
        urlRespuestas(item,ruta){
          
          console.log(ruta)
          console.log(item)
          let url = ruta+'/'+item.token+'/'+item.nombre+'.'+item.tipo
          console.log(url)
          return url
        },
        generarIcon(item){

            let icon 
            switch (item.tipo) {
              case 'pdf':
                icon = 'mdi-file-pdf'
                  
                break;
              case 'xlsx':
                  icon = 'mdi-microsoft-excel'
                break;
              case 'txt':
                  icon = 'mdi-file-document-outline'
                break;
              case 'csv':
                  icon = 'mdi-file-document-outline'
                break;
                case 'xls':
                  icon = 'mdi-file-document-outline'
                break;   
              case 'word':
                  icon = 'mdi-microsoft-word'
                break;
              case 'doc':
                  icon = 'mdi-microsoft-word'
                break; 
              case 'docx':
                  icon = 'mdi-microsoft-word'
                break;                                     
            
              default:
                
                icon = 'mdi-book-open-variant'
    
                break;
            }
    
            return icon 
    
        },
        refresh_folders(carpetas){
          let refresh_folders = [];
          for (let index = 0; index < carpetas.length; index++) {

              let aux_item_carpetas = JSON.parse(carpetas[index])
              
              const estado = typeof aux_item_carpetas.estado =='undefined' ? 'habilitada': aux_item_carpetas.estado
              
              refresh_folders.push({
                  nombre: aux_item_carpetas.nombre,
                  meta_datos: [],
                  archivos: [],
                  estado: estado,
              })
              
              //Agregar meta datos para los buscadores
              

              for (let index2 = 0; index2 < aux_item_carpetas.meta_datos.length; index2++) {
      
                  refresh_folders[index].meta_datos.push(aux_item_carpetas.meta_datos[index2])
                  
              }
              //Agregar archivos para mostrar
              for (let index2 = 0; index2 < aux_item_carpetas.archivos.length; index2++) {
              
                  refresh_folders[index].archivos.push(aux_item_carpetas.archivos[index2])
              
              }
              
          }

          return refresh_folders
        }
    }
  }
  
  export default folders 