<template>
  <v-tooltip dark bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="indigo darken-4" fab small v-bind="attrs" v-on="on">
        <a :href="urlRespuestas" target="_blank">
          <v-icon>{{ icon }}</v-icon>
        </a>
      </v-btn>
    </template>
    <span> {{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "ToolTip",
    },

    urlRespuestas: {
      type: String,
      default: "ToolTip",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
