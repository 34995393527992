<template>
  <v-sheet :max-width="width_archivo" class="">
    <v-row>
      <v-col cols="12" sm="6">
        <div v-text="'Opciones'" class="header font-weight-medium"></div>
        <v-row>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  fab
                  color="indigo darken-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    width_archivo = width_archivo * 2;
                    height_archivo = height_archivo * 2;
                  "
                >
                  <v-icon color="indigo">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Ampliar</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  fab
                  text
                  color="indigo darken-4 "
                  @click="
                    width_archivo = width_archivo / 2;
                    height_archivo = height_archivo / 2;
                  "
                  ><v-icon color="indigo">mdi-minus</v-icon></v-btn
                >
              </template>
              <span>Disminuir</span>
            </v-tooltip>
          </v-col>
          <!-- <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="indigo darken-4"
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <a :href="urlRespuestas(archivo, ruta)" target="_blank">
                    <v-icon>mdi-download</v-icon>
                  </a>
                </v-btn>
              </template>
              <span>Descarga</span>
            </v-tooltip>  
          </v-col> -->

          <v-col>
            <ToolTip
              :urlRespuestas="urlRespuestas(archivo, ruta)"
              :text="'Pantalla completa'"
              :icon="'mdi-eye'"
            ></ToolTip>
          </v-col>
          <v-col v-if="esImagen(archivo.tipo)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="indigo darken-4"
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="rotate_image(archivo)"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>Rotar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="mostrar_carpeta_publica == false" cols="12" sm="6">
        <v-row>
          <div
            v-text="'Más opciones'"
            class="header font-weight-medium text-center"
          ></div>
        </v-row>

        <v-row>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  color="red darken-4"
                  fab
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    $store.state.dataUser.dataUser.nombre != archivo.autor
                  "
                  @click="dialogo_confirmar_eliminacion = true"
                >
                  <v-icon color="">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  color="green darken-4"
                  fab
                  v-bind="attrs"
                  v-on="on"
                  @click="seleccionar"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Seleccionar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row fluid style="border: solid black">
      <v-col>
        <v-img
          class="mx-auto"
          contain
          :height="height_archivo - 100"
          :src="urlRespuestas(archivo, ruta)"
          v-if="esImagen(archivo.tipo)"
        >
        </v-img>
        <!-- v-else-if="
            archivo.tipo != 'docx' &&
              archivo.tipo != 'xlsx' &&
              archivo.tipo != 'pptx' &&
              archivo.tipo != 'text'
          " -->
        <a
          class="mx-auto"
          v-else-if="archivo.tipo != 'pdf'"
          :href="urlRespuestas(archivo, ruta)"
          target="_blank"
          style="text-decoration: none"
        >
          <v-sheet height="100" min-width="250" class="mx-auto">
            <v-container class="mt-4">
              <v-row align="center" justify="center">
                <v-col cols="">
                  <v-container>
                    <div class="header text-center font-weight-medium">
                      <v-icon large dark color="indigo">
                        {{ generarIcon(archivo) }}
                      </v-icon>
                      {{ archivo.nombre }}
                    </div>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </a>
        <!-- <v-sheet v-else-if="archivo.tipo != 'pdf'" rounded="" class="mx-auto">
          <v-row>
            <VueDocPreview
              :height="height_archivo"
              :value="
                'https://drive.educandote.co/audidoc/' +
                  archivo.token +
                  '/' +
                  archivo.nombre +
                  '.' +
                  archivo.tipo
              "
              :type="'office'"
            />
          </v-row>
        </v-sheet> -->
        <v-sheet v-else rounded="" class="mx-auto">
          <v-row>
            <v-col>
              <v-row v-if="load_pdf == false">
                <v-col>
                  <h3>Cargando imagenes...</h3>
                  <v-progress-linear
                    indeterminate
                    color="red darken-3"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="d-flex justify-center">
                  <v-btn @click="pagina_anterior()" icon>
                    <v-icon> mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-btn @click="pagina_siguiente()" icon>
                    <v-icon> mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="d-flex justify-center">
                  <h3>
                    Pagina: {{ pagina_actual }}/{{ cantidad_paginas_pdf }}
                  </h3>
                </v-col>
              </v-row>
              <pdf
                :src="urlRespuestas(archivo, ruta)"
                @num-pages="cantidad_paginas_pdf = $event"
                :page="pagina_actual"
                @loaded="loaded()"
              ></pdf>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="">
        <v-list>
          <v-subheader>Detalles</v-subheader>
          <v-list-item>
            <v-list-item-avatar
              ><v-icon color="indigo">mdi-account</v-icon></v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title>Autor</v-list-item-title>
              <v-list-item-subtitle>{{ archivo.autor }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar
              ><v-icon color="indigo">mdi-chart-pie</v-icon></v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title>Area</v-list-item-title>
              <v-list-item-subtitle>{{
                archivo.nombre_sector
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list>
          <v-subheader>...</v-subheader>
          <v-list-item>
            <v-list-item-avatar
              ><v-icon color="indigo"
                >mdi-calendar-clock</v-icon
              ></v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title>Fecha registrada</v-list-item-title>
              <v-list-item-subtitle>{{
                calcular_fecha(archivo)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <!--
        <v-col>
          <ArchivosSeleccionados />
        </v-col>
        -->
    </v-row>
    <v-dialog v-model="dialogo_confirmar_eliminacion" max-width="400">
      <v-card>
        <v-card-text>
          <h3 style="text-align:center">
            Estas seguro que deseas eliminar el archivo {{ archivo.nombre }} ?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogo_confirmar_eliminacion = false" dark color="red"
            >Cancelar</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn @click="DELETE_CASO()" color="indigo" dark>Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>
<script>
// import VueDocPreview from "vue-doc-preview";
import ToolTip from "../components/ToolTip.vue";
import folders from "../mixins/folders";
import pdf from "vue-pdf";
//import ArchivosSeleccionados from './ArchivosSeleccionados'
import { EventBus } from "../event/event-bus";
const axios = require("axios").default;
export default {
  components: {
    // VueDocPreview,
    //ArchivosSeleccionados,
    pdf,
    ToolTip,
  },
  mixins: [folders], //Actualmente solo contiene la funcion de url respuestas y generarIcon
  props: {
    archivo: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    objId_caso: {
      type: String,
      required: false,
    },
    mostrar_carpeta_publica: {
      type: Boolean,
      required: false,
    },
    ruta: {
      type: String,
      default: "https://drive.educandote.co/audidoc",
    },
  },
  data() {
    return {
      width_archivo: 0,
      height_archivo: 0,
      dialogo_confirmar_eliminacion: false,

      cantidad_paginas_pdf: 0,
      pagina_actual: 1,
      currentPage: 1,
      load_pdf: false,
    };
  },
  created() {
    console.log(this.archivo);

    this.width_archivo = this.width;
    this.height_archivo = this.height;
  },
  watch: {
    archivo() {
      console.log("Cambio de archivo");
      this.load_pdf = false;
      this.pagina_actual = 1;
      this.cantidad_paginas_pdf = 1;
    
      //
    },
  },
  methods: {
    esImagen(documento) {
      return (
        documento == "bmp" ||
        documento == "svg" ||
        documento == "jpge" ||
        documento == "jpeg" ||
        documento == "blob" ||
        documento == "png" ||
        documento == "jpg"
      );
    },
    pagina_anterior() {
      if (this.pagina_actual > 1) {
        this.pagina_actual--;
      }
    },
    pagina_siguiente() {
      if (this.cantidad_paginas_pdf > this.pagina_actual) {
        this.pagina_actual++;
      }
    },
    async loaded() {
      console.log();
      this.load_pdf = true;
    },

    async rotate_image(archivo) {
      try {
        let url = await this.urlRespuestas(archivo, this.ruta);
        console.log(url);
        url = this.$store.dispatch("utils/FORMAT_BASE64", url);

        let response = await this.$store.dispatch("llamado_post", {
          url: "https://drive.educandote.co/audidoc/rotate.php",
          body: {
            url: url,
            sentido: "1",
          },
          tipo_header: "php",
        });
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    calcular_fecha(archivo) {
      let fecha = "Fecha no registrada";
      if (typeof archivo.fecha_adjuntado != "undefined") {
        console.log(archivo.fecha_adjuntado);
        fecha = new Date(archivo.fecha_adjuntado).toISOString().substr(0, 10);
      }

      if (typeof archivo.fecha_creacion_int != "undefined") {
        console.log(archivo.fecha_creacion_int);
        fecha = new Date(archivo.fecha_creacion_int)
          .toISOString()
          .substr(0, 10);
      }

      return fecha;
    },
    async seleccionar() {
      console.log("Funcion Seleccionar: ");
      console.log(this.objId_caso);
      console.log(this.archivo);
      let obj = {
        nombre: this.archivo.nombre,
        token: this.archivo.token,
        tipo: this.archivo.tipo,
        autor: this.archivo.autor,
        sector: this.archivo.sector,
        nombre_sector: this.archivo.nombre_sector,
        fecha_adjuntado: this.archivo.fecha_adjuntado,
      };
      console.log(obj);
      this.$store.commit("agregar_archivo", this.archivo);
    },
    async GET_CASO(endpoint, objectId, type_search, obj) {
      let res;
      this.$store.commit("auth", "parse");
      console.log(endpoint);

      switch (type_search) {
        case "objectId":
          res = await axios.get(
            "https://audidoc.educandote.co/parse/classes/" +
              endpoint +
              "/" +
              objectId,
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );

          break;
        case "where":
          res = await axios.get(
            "https://audidoc.educandote.co/parse/classes/" +
              endpoint +
              "?where=" +
              obj,
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );

          break;
      }
      console.log(res);
      return res;
    },
    async DELETE_CASO() {
      try {
        console.log(this.archivo);
        let archivo_caso = JSON.stringify({
          nombre: this.archivo.nombre,
          token: this.archivo.token,
          tipo: this.archivo.tipo,
          autor: this.archivo.autor,
          sector: this.archivo.sector,
          nombre_sector: this.archivo.nombre_sector,
          fecha_adjuntado: this.archivo.fecha_adjuntado,
        });

        console.log(archivo_caso);
        console.log(this.objId_caso);
        /*
        let res = await this.GET_CASO(
            this.$store.state.dataUser.dataUser.rango == "Supervisor"
            ? "prueba01casos"
            : "prueba01casosEmpleados",
          this.objId_caso,
          "objectId",
          null
        );
         console.log(res.data);
        */

        if (this.$store.state.dataUser.dataUser.rango == "Supervisor") {
          this.$store.commit("auth", "parse");
          let archivo_elim = await axios.put(
            "https://audidoc.educandote.co/parse/classes/prueba01casos/" +
              this.objId_caso,
            {
              informacion_area: {
                __op: "Remove",
                objects: [archivo_caso],
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );
          console.log(archivo_elim);
          console.log("Se elimino de prueba01Casos");
          EventBus.$emit("recargarSupervisor", {
            url: "https://audidoc.educandote.co/parse/classes/prueba01casos/",
            objectId: this.objId_caso,
          });
        } else if (this.$store.state.dataUser.dataUser.rango == "Empleado") {
          // Aqui se elimina el array[x] directamente con el objId_caso
          this.$store.commit("auth", "parse");
          let archivo_elim = await axios.put(
            "https://audidoc.educandote.co/parse/classes/prueba01casosEmpleados/" +
              this.$route.params.data.data.objectId,
            {
              datos_adjuntos: {
                __op: "Remove",
                objects: [archivo_caso],
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );
          console.log(archivo_elim.data);

          //Se busca el objectId usando las llaves del caso actual en el prueba01Casos

          console.log(this.$route.params);
          //Aqui se elimina de prueba01Casos con el id obtenido de la res anterior
          let archivo_elim_empl = await axios.put(
            "https://audidoc.educandote.co/parse/classes/prueba01casos/" +
              this.$route.params.data.data.object_id_caso_supervisor,
            {
              informacion_area: {
                __op: "Remove",
                objects: [archivo_caso],
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );
          console.log(archivo_elim_empl);
          EventBus.$emit("recargarEmpleado", {
            url:
              "https://audidoc.educandote.co/parse/classes/prueba01casosEmpleados/",
            objectId: this.$route.params.data.data.objectId,
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Algo ha salido mal eliminando el archivo " + error.message
        );
      }
      this.dialogo_confirmar_eliminacion = false;
    },
  },
};
</script>
<style></style>
